import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from './components/Toast.vue'
import V2Popup from 'v2popup'
import 'v2popup/dist/v2popup.css'
import axios from 'axios'
import '@/assets/css/base.css'

// 定义插件对象
const ToastObj = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ToastConstructor = Vue.extend(Toast)
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ToastConstructor()
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement('div'))
    document.body.appendChild(instance.$el)

    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$toast = (msg, duration = 2000) => {
      instance.message = msg
      instance.visible = true
      setTimeout(() => {
        instance.visible = false
      }, duration)
    }
  }
}
// https://pay.iscett.com
// http://192.168.0.160:8787
axios.defaults.baseURL = 'https://pay.iscett.com'
Vue.prototype.$http = axios
Vue.prototype.$popup = V2Popup
Vue.prototype.$backendUrl = 'https://pay.iscett.com'

Vue.use(ToastObj)
Vue.use(V2Popup)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
